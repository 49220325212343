/*
 * @Author: your name
 * @Date: 2021-04-13 20:40:31
 * @LastEditTime: 2021-04-14 21:24:55
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\externalLink\index.tsx
 */
import { defineComponent, toRefs, onMounted, PropType } from "@vue/runtime-core";
import { useRoute } from 'vue-router'
import style from  './index.module.less'
const iFrameResize = require('@/utils/iframeResizer.min')

export default defineComponent({
  props: {
    iframeUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const route = useRoute()
    const iframeUrl = `https://bk.fodu.com/bkView.html?source=${route.query.iframeUrl}` as string
    // const iframeUrl = route.query.iframeUrl as string
    const title = route.query.title as string
    onMounted(() => {
      document.documentElement.scrollTop = 0
      iFrameResize({
        log: false,//开启日志：插件调用过程中相关信息
      })
    })
    return () => {
      return (
        <div class={style.container}>
          <div class={style.title}>佛度百科：{title}</div>
         {/*  <iframe id="ifram" src="https://www.baidu.com" width="100%;height: 600px;"></iframe> */}
          <iframe id="Iframe" class={style.iframe} src={iframeUrl} />
        </div>
      )
    }
  }
})